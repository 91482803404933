<!-- System: STA
    Purpose: This compoment will display user invite 
            link modal in team members page by email or link.
-->
<template>
  <!-- Invite Link Modal -->
  <v-dialog
    max-width="500"
    class="pa-0 white-bg"
    v-model="inviteLinkModal"
    @click:outside="onHidden()"
  >
    <!-- Invite Link Button -->
    <template v-slot:activator="{ on }">
      <v-btn
        class="mt-4 white--text invite_member_btn tm-btn ml-sm-3"
        color="#2758f6"
        elevation="10"
        v-on="on"
        small
        :class="
          activeMembers.length == 1 && checkActiveTeamMember == true
            ? 'promt-btn'
            : ''
        "
      >
        <span class="mdi mdi-plus">Invite Member</span>
      </v-btn>
    </template>
    <!-- Invite Link Card -->
    <v-card class="white-bg">
      <v-card-title>
        <span class="headline">Invite Your Team Member</span>
      </v-card-title>
      <v-container>
        <!-- Copy Link Area -->
        <input
          name="copylink"
          id="copyLink"
          value=""
          type="hidden"
          style="color: white !important; border: none"
        />

        <!--Multiple Project Invite Member Modal Start-->
        <div class="first-modal" v-if="projects_list.active.length > 1">
          <v-divider class="mb-3"></v-divider>
          <!-- Invite By Project Link Component -->
          <InviteLink />
          <v-divider class="mt-3"></v-divider>
          <div class="mt-3">
            <span class="small text-secondary left-margin my-2"
              >Enter the email and select project to send project
              invitation.</span
            >
          </div>
          <!-- Invite Link Form -->
          <v-form
            @submit.prevent="invite_members_submit"
            ref="invite_members_form_first"
            v-model="valid"
          >
            <v-row
              :key="i"
              class="invite-member-form mt-2 ml-2"
              v-for="(account, i) in accounts"
              v-if="accounts.length"
            >
              <!-- Email Text Field -->
              <v-col cols="12" md="6">
                <v-text-field
                  :name="'email' + i"
                  data-vv-as="Member Email"
                  placeholder="Email"
                  type="email"
                  :rules="emailRules"
                  v-model="account.email"
                  @keyup="addRow()"
                  class="name-field mt-4"
                ></v-text-field>
              </v-col>

              <!-- Project List Select/Combobox -->
              <v-col cols="12" md="6">
                <v-combobox
                  v-model="account.project_ids"
                  :items="projects_list_option"
                  item-text="name"
                  item-value="id"
                  label="Select Projects"
                  multiple
                  chips
                  required
                ></v-combobox>
              </v-col>
            </v-row>
            <!-- Send Invitation Button -->
            <v-row class="d-flex justify-center">
              <v-btn
                color="#2758f6"
                class="send-invitation-button white--text mb-5"
                ref="submit"
                type="submit"
                :disabled="invitationLoader"
              >
                <v-progress-circular
                  indeterminate
                  v-if="invitationLoader"
                  size="20"
                >
                </v-progress-circular>
                Send Invitation
              </v-btn>
            </v-row>
          </v-form>
        </div>
        <!--Multiple Project Invite Member Modal End-->

        <!-- Single Project Invite Member Modal Start -->
        <div class="second-modal" v-if="projects_list.active.length === 1">
          <label class="caption"
            >Copy the link to invite team members for default project.</label
          >
          <!-- Invite Link Text Field With Copy Button -->
          <div class="d-flex mt-1">
            <v-text-field
              id="copy-link"
              class="single-project-link"
              v-model="invite_link"
            ></v-text-field>
            <v-btn
              class="white--text mt-5"
              color="#2758f6"
              small
              @click="copyLink()"
              >Copy</v-btn
            >
          </div>

          <div class="col-12 pt-0">
            <!-- Invite Link Form -->
            <v-form
              @submit.prevent="invite_members_submit"
              ref="invite_members_form_second"
              v-model="valid"
            >
              <!-- Email Text Field -->
              <div
                :key="i"
                class="row invite-member-form bottom-margin"
                v-for="(account, i) in accounts"
              >
                <div class="col-9">
                  <v-text-field
                    class="email-field mt-1"
                    label="Email"
                    type="email"
                    :rules="emailRules"
                    v-model="account.email"
                  ></v-text-field>
                </div>
                <!-- Button To Add Multiple Rows -->
                <div class="col-3 pl-3 marginTop d-flex">
                  <button
                    @click="accounts.splice(i, 1)"
                    class="btn pr-2"
                    type="button"
                    v-if="i !== 0"
                  >
                    <img src="../../assets/images/minus icon 2.svg" />
                  </button>
                  <button
                    @click="addRows()"
                    class="btn p-0"
                    type="button"
                    v-if="i === accounts.length - 1"
                  >
                    <img
                      class="plus"
                      src="../../assets/images/plus icon 2.svg"
                    />
                  </button>
                </div>
              </div>
              <!-- Send invitation button -->
              <div class="text-center">
                <v-btn
                  ref="submit"
                  type="submit"
                  color="#2758f6"
                  class="send-invitation-button white--text mb-5 mt-5"
                  :disabled="!valid || invitationLoader"
                >
                  <v-progress-circular
                    indeterminate
                    v-if="invitationLoader"
                    size="20"
                  >
                  </v-progress-circular>
                  Send Invitation
                </v-btn>
              </div>
            </v-form>
          </div>
        </div>
        <!--Single Project Invite Member Modal End-->

        <!-- This Will Compute Invitation Link -->
        {{ computeInviteLink }}
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { emailRules, required } from "@/Constants/Const";
import image from "../../assets/images/sample.png";
import { mapState } from "vuex";
import { min_to_hour } from "../../assets/js/utils";

export default {
  props: ["new", "activeMembers"],
  components: {
    InviteLink: () => import("@/components/TeamMembers/InviteLink"),
  },
  name: "InviteTeamMember",
  data() {
    return {
      inviteLinkModal: false,
      emailRules: emailRules,
      invitationLoader: false,
      valid: true,
      showCopyMsg: false,
      invite_link: null,
      value: [],
      errorMsg: "",
      rows: [{ showRow: true }],
      invite_members: {
        dismissSecs: 5,
        dismissCountDown: 0,
        variant: "",
        msg: "",
        showDismissibleAlert: false,
      },
      showDismissibleAlert: false,
      selected: [],
      projects: [],
      accounts: [
        {
          interval: 1,
          email: "",
          project_ids: [],
          errorMsg: "",
        },
      ],
    };
  },
  filters: {
    capitalize: function (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
  },
  computed: {
    ...mapState("custom", [
      "team_members_projects_list",
      "selected_company",
      "projects_list",
      "active_team_members_list",
    ]),
    /**
     * Return the project list
     */
    projects_list_option() {
      let project_options = [];
      if (this.projects_list.active.length >= 1) {
        this.projects_list.active.forEach((project) => {
          project_options.push({ name: project.project_name, id: project.id });
          this.projects.push({ name: project.project_name, id: project.id });
        });
      } else {
        project_options.push({
          name: "No Project Found",
          id: "null",
          disabled: true,
        });
      }
      return project_options;
    },
    checkActiveTeamMember() {
      let check = false;
      if (this.active_team_members_list.length > 0) {
        if (this.active_team_members_list[0].monthly_minutes == null) {
          check = true;
        }
      }
      return check;
    },
    computeInviteLink() {
      if (this.projects_list.active.length == 0 && this.inviteLinkModal) {
        return this.getProjects();
      } else if (
        this.projects_list.active.length == 1 &&
        this.inviteLinkModal
      ) {
        this.createLink();
      } else {
        if (this.new === "yes" && this.inviteLinkModal) {
          this.createLink();
        }
      }
    },
  },

  created() {},

  mounted() {
    this.check_if_from_set_password_page();
  },

  methods: {
    /**
     * This function is resposible for getting
     * the list of projects
     */
    getProjects() {
      this.$store.commit("custom/toggle_loader", false);
      this.$store
        .dispatch("custom/project_list_api", {
          company_id: this.$route.params.id,
        })
        .then((response) => {
          this.createLink();
          if (this.new === "yes") {
            this.inviteLinkModal = true;
          }
        });
    },
    invited_members() {
      return this.active_team_members_list.filter(
        (member) => member.is_active === null
      );
    },
    /**
     * This function is resposible for createing
     * a link by api call
     */
    createLink() {
      if (this.$store.state.custom.projects_list.active.length >= 1) {
        let project = this.$store.state.custom.projects_list.active;
        let projects = project[0].id;
        let data = {
          company_id: this.$route.params.id,
          project_id: projects,
        };
        this.$store
          .dispatch("custom/createLink", data)
          .then((response) => {
            this.invite_link = response.data.invite_link;
            this.$store.commit("custom/toggle_loader", false);
            if (this.new === "yes") {
              // this.$refs.multiple_invite_modal.show();
            }
          })
          .catch((error) => {
            console.log(error);
            this.$store.commit("custom/toggle_loader", false);
          });
      }
    },
    /**
     * This function is resposible for
     * copying a link
     */
    copyLink() {
      var copyText = document.getElementById("copy-link");
      copyText.type = "text";
      copyText.focus();
      copyText.select();
      document.execCommand("copy");
      this.$root.$emit("snack_bar", {
        show: true,
        message: "Link Copied ",
        snackbarColor: "green",
      });
    },

    getgtag() {
      gtag("event", "submit", {
        event_category: "onboarding",
        event_label: "Team Members invited",
        value: 3,
      });
    },
    /**
     * This function is resposible
     * for summiting form data for
     * inviting new users (api call)
     */
    invite_members_submit(e) {
      e.preventDefault();
      let emails = this.accounts.map((account) => {
        return account.email;
      });
      let checkEmail = emails.length;
      if (checkEmail == 1) {
        if (emails["0"] == "") {
          setTimeout(() => (this.requiredError.email = ""), 3000);
          this.requiredError.email = "Email field is required";
          this.$root.$emit("snack_bar", {
            show: true,
            message: "The email field is required",
            snackbarColor: "red",
          });
          return;
        }
      }
      let check_project = false;
      let project_ids = this.accounts.map((account) => {
        if (this.projects_list.active.length === 1) {
          return [this.projects_list.active[0]];
        } else {
          if (account.email) {
            if (account.project_ids.length == 0) {
              check_project = true;
            }
          }
          return account.project_ids;
        }
      });

      if (check_project) {
        this.requiredError.project = "Please select a project first";
        setTimeout(() => (this.requiredError.project = ""), 3000);
        this.$root.$emit("snack_bar", {
          show: true,
          message: "The project field is required",
          snackbarColor: "red",
        });
        return;
      }

      let intervals = this.accounts.map((account) => {
        return account.interval;
      });

      let inviteMem = {
        intervals: intervals,
        emails: emails.filter(function (e) {
          return e;
        }),
        project_ids: project_ids.filter(function (e) {
          return (e = null || e != "");
        }),
        company_id: this.$route.params.id,
      };
      this.$store.commit("custom/toggle_loader", true);
      this.invitationLoader = true;
      this.$store
        .dispatch("custom/invite_members_to_project", inviteMem)
        .then((response) => {
          this.invitationLoader = false;
          this.$store.commit("custom/toggle_loader", false);
          if (response.data.error === false) {
            this.$store.commit("custom/set_loader", false);
            this.$store.dispatch("custom/get_active_team_members_list", {
              company_id: this.$route.params.id,
            });
            this.onHidden();
            this.$root.$emit("snack_bar", {
              show: true,
              message: response.data.error_msg,
              snackbarColor: "green",
            });
            this.getgtag();
          } else {
            this.$root.$emit("snack_bar", {
              show: true,
              message: response.data.message,
              snackbarColor: "red",
            });
            this.$store.commit("custom/set_loader", false);
          }
        })
        .catch((error) => {
          this.invitationLoader = false;
          this.$store.commit("custom/toggle_loader", false);
          if (error.response.status == 422) {
            setTimeout(() => {
              this.$root.$emit("snack_bar", {
                show: true,
                message: error.response.data.errors["project_ids.0.0"][0],
                snackbarColor: "red",
              });
            }, 1000);
            this.accounts.forEach((account) => {
              Object.keys(error.response.data.errors).forEach((key) => {
                if (key.includes("project_ids")) {
                  let values = key.split(".");
                  if (
                    values[1] === this.accounts.indexOf(account) &&
                    values.length > 2
                  ) {
                    account.errorMsg =
                      account.project_ids[values[2]].name +
                      " is already assigned to this user.";
                  } else if (values.length === 2) {
                    account.errorMsg = error.response.data.errors[key][0];
                  }
                }
              });
            });
          }
          if (error.response.status == 403) {
            setTimeout(() => {
              this.$root.$emit("snack_bar", {
                show: true,
                message: error.response.data.error_msg,
                snackbarColor: "red",
              });
            }, 1000);
          }

          if (error.response.data.errors.emails) {
            this.$root.$emit("snack_bar", {
              show: true,
              message: error.response.data.errors.emails[0],
              snackbarColor: "red",
            });
          }

          if (error.response.data.errors) {
            this.$root.$emit("snack_bar", {
              show: true,
              message: error.response.data.message,
              snackbarColor: "red",
            });
          }

          if (error.response.data.errors.project_ids + i) {
            this.$root.$emit("snack_bar", {
              show: true,
              message: error.response.data.errors.project_ids + i,
              snackbarColor: "red",
            });
          }
          if (error.response.status == 500) {
            this.$root.$emit("snack_bar", {
              show: true,
              message: "Select all please",
              snackbarColor: "red",
            });
          }
          if (error.response.data.errors.designations + i) {
            this.$root.$emit("snack_bar", {
              show: true,
              message: error.response.data.errors.designations + i,
              snackbarColor: "red",
            });
          }
        });
    },
    /**
     * This function is resposible
     * for adding new row in  form
     */
    addRow() {
      let accounts = this.accounts.find((data) => data.email == "");
      if (!accounts) {
        this.accounts.push({
          interval: 1,
          email: "",
          project_ids: [],
          errorMsg: "",
        });
      }
    },
    /**
     * This function is resposible
     * for adding new row in  form
     */
    addRows() {
      this.accounts.push({
        interval: 1,
        email: "",
        project_ids: [],
        errorMsg: "",
      });
    },

    onHidden() {
      this.inviteLinkModal = false;
      if (this.$refs.invite_members_form_first) {
        this.$refs.invite_members_form_first.reset();
      }
      if (this.$refs.invite_members_form_second) {
        this.$refs.invite_members_form_second.reset();
      }
      this.selected = null;
      this.showCopyMsg = false;
      this.accounts = "";
      this.accounts = [
        {
          interval: 1,
          email: "",
          project_ids: [],
          errorMsg: "",
        },
      ];
    },
    check_if_from_set_password_page() {
      if (this.$route.query.new === "yes") {
        document.querySelector(".invite_member_btn").click();
      }
    },
  },
};
</script>

<style scoped>
.tm-btn {
  height: 40px !important;
  border-radius: 4px !important;
  background-color: #2758f6 !important;
}

.error-msg {
  color: tomato;
  font-size: 12px;
}
.promt-btn {
  width: 175px !important;
  border-radius: 5px;
  height: 35px;
  text-align: center;
  vertical-align: center;
  background-color: #304ffe;
  z-index: 1 !important;
  animation-name: bounce-2 !important;
  animation-timing-function: ease !important;
  animation-duration: 2s !important;
  animation-iteration-count: infinite !important;
}
@keyframes bounce-2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
</style>